<template>
  <div>
    <b-card :title="$t('g.correct_action_requests')">
      <div class="custom-search d-flex justify-content-between">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">{{ $t("g.searchLabel") }}</label>
            <b-form-input
              v-model="searchTerm"
              :placeholder="$t('g.searchPlaceholder')"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
        <div v-if="sub_Permissions[1].store">
          <b-button
            :disabled="sent_for_tech_review_date"
            :to="{
              name: 'AddCorrectiveActionRequest',
              params: { id: currentId, tech_id: tectEvaluationId },
            }"
            variant="primary"
            >{{ $t("g.AddCorrectAction") }}</b-button
          >
        </div>
      </div>

      <vue-good-table
        :columns="correctActionsColumns"
        :rows="correctActions"
        :rtl="direction"
        styleClass="vgt-table condensed"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.label === 'raised_by'" class="text-nowrap">
            {{ $t("g.raised_by") }}
          </span>
          <span
            v-else-if="props.column.label === 'assigned_by'"
            class="text-nowrap"
          >
            {{ $t("g.assigned_by") }}
          </span>
          <span
            v-else-if="props.column.label === 'root_analysis_required'"
            class="text-nowrap"
          >
            {{ $t("g.root_analysis_required") }}
          </span>
          <span
            v-else-if="props.column.label === 'comments_action_taken'"
            class="text-nowrap"
          >
            {{ $t("g.comments_action_taken") }}
          </span>
          <span
            v-else-if="props.column.label === 'nc_description'"
            class="text-nowrap"
          >
            {{ $t("g.nc_description") }}
          </span>
          <span
            v-else-if="props.column.label === 'root_cause'"
            class="text-nowrap"
          >
            {{ $t("g.root_cause") }}
          </span>
          <span
            v-else-if="props.column.label === 'proposed_action'"
            class="text-nowrap"
          >
            {{ $t("g.proposed_action") }}
          </span>
          <span
            v-else-if="props.column.label === 'corrective_action'"
            class="text-nowrap"
          >
            {{ $t("g.corrective_action") }}
          </span>
          <span
            v-else-if="props.column.label === 'request_date'"
            class="text-nowrap"
          >
            {{ $t("g.request_date") }}
          </span>
          <span
            v-else-if="props.column.label === 'closing_date'"
            class="text-nowrap"
          >
            {{ $t("g.closing_date") }}
          </span>
          <span v-else-if="props.column.label === 'action'" class="text-nowrap">
            {{ $t("g.action") }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>
        <template slot="table-row" slot-scope="props">
          <span
            v-if="props.column.field === 'root_analysis_required_row'"
            class="text-nowrap"
          >
            {{
              props.row.root_analysis_required
                ? $t("g.required")
                : $t("g.not required")
            }}
          </span>
          <span
            v-else-if="props.column.field === 'comments_action_taken_row'"
            class="text-nowrap"
          >
            {{
              props.row.comments_action_taken
                ? $t("g.required")
                : $t("g.not required")
            }}
          </span>
          <span
            v-if="props.column.field === 'request_date_row'"
            class="text-nowrap"
          >
            {{ format(props.row.request_date) }}
          </span>
          <span
            v-if="props.column.field === 'closing_date_row'"
            class="text-nowrap"
          >
            {{ format(props.row.closing_date) }}
          </span>
          <span v-if="props.column.field === 'action'">
            <span>
              <b-button
                v-if="sub_Permissions[4].destroy"
                variant="light-danger"
                @click="deleteAlert(props.row.id)"
              >
                <b-avatar size="32" variant="light-danger">
                  <feather-icon icon="Trash2Icon" size="18" />
                </b-avatar>
              </b-button>
            </span>
          </span>

          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                {{ $t("g.pagelength") }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap">
                {{ $t("g.of") }} {{ props.total }}
                {{ $t("g.pageText2") }}
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
  BButton,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import formatDate from "@/composables/format-date/format-date";

export default {
  components: {
    BCard,
    VueGoodTable,
    BAvatar,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    correctActions: {
      type: Array,
      default: [],
    },
    currentId: String,
    tectEvaluationId: String,
    sub_Permissions: Object,
    sent_for_tech_review_date: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      pageLength: 10,
      dir: false,
      correctActionsColumns: [
        {
          label: "raised_by",
          field: "raised_by",
        },
        {
          label: "assigned_by",
          field: "assigned_by",
        },
        {
          label: "root_analysis_required",
          field: "root_analysis_required_row",
        },
        {
          label: "comments_action_taken",
          field: "comments_action_taken_row",
        },
        {
          label: "nc_description",
          field: "nc_description",
        },
        {
          label: "root_cause",
          field: "root_cause",
        },
        {
          label: "proposed_action",
          field: "proposed_action",
        },
        {
          label: "corrective_action",
          field: "corrective_action",
        },
        {
          label: "request_date",
          field: "request_date_row",
        },
        {
          label: "closing_date",
          field: "closing_date_row",
        },
        {
          label: "action",
          field: "action",
        },
      ],
      format: null,
      searchTerm: "",
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.format = formatDate;
  },
  methods: {
    deleteCorrectAction(id) {
      this.correctActions = this.correctActions.filter(
        (item) => item.id !== id
      );
    },

    deleteAlert(id) {
      this.$swal({
        title: this.$t("g.areYouSure?"),
        text: this.$t("g.youWontBeAbleToRevertThis"), // You won't be able to revert this!
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("g.yesDeleteIt"), // "Yes, delete it!" ,
        cancelButtonText: this.$t("g.cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$swal({
            icon: "success",
            title: this.$t("g.deleted"), //"Deleted!"
            text: this.$t("g.yourFileHasBeenDeleted"), //"Your file has been deleted."
            customClass: {
              confirmButton: "btn btn-success",
            },
          });

          this.$http
            .delete(
              `admin/serviceRequests/${this.currentId}/techEvaluations/${this.tectEvaluationId}/correctActionRequests/${id}`
            )
            .then((res) => {
              deleteCorrectAction(id);
            })
            .catch((err) => console.log(err));
        }
      });
    },
  },
};
</script>
