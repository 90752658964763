<template>
  <loading v-if="isLoading" />
  <error v-else-if="isError" />
  <content-not-view
    v-else-if="!sub_Permissions.techEvaluationsReviews[0].index"
  />

  <div v-else>
    <b-card :title="$t('g.techEvaluationReport')">
      <b-row>
        <b-col xs="6" class="mb-1">
          <b-button variant="primary" @click="handleSendTechEvaluation"
          :disabled="samples.length <= 0">{{
            sent_for_tech_review_date
              ? $t("g.viewTheTechnicalReport")
              : $t("g.FinishTheTechnicalEvaluation")
          }}</b-button>
        </b-col>
        <b-col xs="6" class="mb-1">
          <div class="custom-search d-flex justify-content-end">
            <b-button
              :to="{
                name: 'ViewServiceRequest',
                params: { id: currentId },
              }"
              variant="primary"
              >{{ $t("g.backToServiceRequest") }}</b-button
            >
          </div>
        </b-col>
      </b-row>
    </b-card>

    <samples-request
      :samples="samples"
      :current-id="currentId"
      :tect-evaluation-id="tectEvaluationId"
      :sub_Permissions="sub_Permissions.samples"
      v-if="sub_Permissions.samples[0].index"
      :sent_for_tech_review_date="sent_for_tech_review_date"
    />
    <corrective-request
      :correct-actions="correctActions"
      :current-id="currentId"
      :tect-evaluation-id="tectEvaluationId"
      :sub_Permissions="sub_Permissions.correctActionRequests"
      v-if="sub_Permissions.correctActionRequests[0].index"
      :sent_for_tech_review_date="sent_for_tech_review_date"
    />
    <improvement-requests
      :improve="improve"
      :current-id="currentId"
      :tect-evaluation-id="tectEvaluationId"
      :sub_Permissions="sub_Permissions.improveRequests"
      v-if="sub_Permissions.improveRequests[0].index"
      :sent_for_tech_review_date="sent_for_tech_review_date"
    />
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { BRow, BCard, BCol, BButton } from "bootstrap-vue";
import store from "@/store/index";
import SamplesRequest from "./samplesRequest.vue";
import CorrectiveRequest from "./correctiveRequest.vue";
import ImprovementRequests from "./improvementRequests.vue";
import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
import ContentNotView from "@/views/components/logic/contentNotView.vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    SamplesRequest,
    CorrectiveRequest,
    ImprovementRequests,
    Loading,
    Error,
    ContentNotView,
    BButton,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      rows: [],
      samples: [],
      improve: [],
      correctActions: [],
      currentId: null,
      tectEvaluationId: null,
      isLoading: true,
      isError: false,
      sub_Permissions: {},
      sent_for_tech_review_date: null,
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  beforeMount() {
    this.currentId = this.$route.params.id;
    this.tectEvaluationId = this.$route.params.tech_id;
    this.$http
      .get(
        `admin/serviceRequests/${this.currentId}/techEvaluations/${this.tectEvaluationId}`
      )
      .then((res) => {
        
        this.rows = res.data.data;
        this.samples = res.data.data.samples;
        this.improve = res.data.data.improve_requests;
        this.correctActions = res.data.data.correct_action_requests;

        setTimeout(() => {
          this.isLoading = false;
          this.isError = false;
          store.dispatch("GET_PERMISSIONS", [
            "improveRequests",
            "correctActionRequests",
            "samples",
            "techEvaluationsReviews",
          ]);
          this.sub_Permissions = store.state.permissions.sub_Permissions;
          // console.log(store.state.permissions.sub_Permissions);
        }, 1000);
      })
      .catch((error) => {
        console.log(error.message);
        this.isLoading = false;
        this.isError = true;
      });

    this.$http
      .get(`admin/serviceRequests/${this.currentId}`)
      .then((res) => {
        this.sent_for_tech_review_date = res.data.data.sent_for_tech_review_date;
      })
      .catch((error) => {
        console.log(error.message);
        this.isLoading = false;
        this.isError = true;
      });
  },
  methods: {
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    handleSendTechEvaluation() {
      // if (res.data.data.tech_evaluation.samples) {
        if (this.sent_for_tech_review_date) {
          this.$router.push({
            name: "ViewTechEvaluationReport",
            params: { id: this.currentId, tech_id: this.tectEvaluationId },
          });
        } else {
          this.$router.push({
            name: "AddTechEvaluation",
            params: { id: this.currentId, tech_id: this.tectEvaluationId },
          });
        }
      // } else {
      //   this.makeToast(
      //     "danger",
      //     this.$t("g.send.errorBody"),
      //     this.$t("g.send.errorTitle")
      //   );
      // }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
